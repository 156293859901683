import React from "react"
import { Helmet } from "react-helmet"
import { withPrefix, Link } from "gatsby"
import $ from "jquery"
import Layout from "../components/layout"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import ReactFullpage from '@fullpage/react-fullpage';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

import Footer from "../components/footer"
import Drawer from "../components/drawer"

import "../styles/shul.scss"

import vaziraniV from "../images/vazirani-v.png"
import vaziraniFullLogo from "../images/vazirani-full-logo-yellow.png"
import hamMenuIcon from "../images/ham_menu_icon.svg"
import hamMenuClose from "../images/ham_menu_close.svg"
import dropDownArrow from "../images/drop-down-icon.svg"
import muteIcon from "../images/icons/Mute.svg"
import unmuteIcon from "../images/icons/Unmute.svg"

// import ekonkpic1 from "../images/ekonk/nicolas-lobos-NR_tXTuyTak-unsplash.jpg"
// import ekonkpic2 from "../images/ekonk/mike-kiev-Opzk_hvwO9Q-unsplash.jpg"

import dicologofull from "../images/dico/DICO LOGO_full.png"
import dicopic1 from "../images/dico/1.jpg"
import dicopic2 from "../images/dico/ekonk-red-top.png"
import weightreduction from "../images/dico/weightreduction.png"
import range from "../images/dico/range.png"
import ekonklogofull from "../images/ekonk/Ekonk and Dico_final LINES.webp"

import shulLoopVideo from "../videos/51 - Shul Final Car Loop Sequence 25 fps.mp4"
import dicoTopVideo from "../videos/dico/Dico Battery_main edit_v3_Trim.mp4"

//React image gallery plugin
import ImageGallery from 'react-image-gallery';

const images = [
  {
    original: 'rge',
  },
  {
    original: 'https://i.ibb.co/cNN16j5/9-DESIGN-GALLERY-Fotor3.jpg',
  },
  {
    original: 'https://i.ibb.co/JCFRPLp/13-DESIGN-GALLERY.jpg',
  },
  {
    original: 'https://i.ibb.co/nkfsRDZ/DESIGN-GALLERY-VR-Fotor3.jpg',
  },
  {
    original: 'https://i.ibb.co/GR2ycFG/DESIGN-GALLERY-Fotor43.jpg',
  },
  {
    original: 'https://i.ibb.co/WWQCTSh/DESIGN-GALLERY2-Fotor2-Fotore.jpg',
  },
  {
    original: 'https://i.ibb.co/RHQXLJX/5-DESIGN-GALLERY.jpg',
  },
  {
    original: 'https://i.ibb.co/rZJF2MQ/11-DESIGN-GALLERY.jpg',
  },
  {
    original: 'https://i.ibb.co/W5Xygg2/5-DESIGN-GALLERYaero.jpg',
  },
  {
    original: 'https://i.ibb.co/rtKW1bc/2-DESIGN-GALLERY-Fotor.jpg',
  },
];

const SEL = 'custom-section';
const SECTION_SEL = `.${SEL}`;

const pluginWrapper = () => {
  /*
  * require('./fullpage.fadingEffect.min'); // Optional. Required when using the "fadingEffect" extension.
  */
};

// markup
class EkonkPage extends React.Component {

  componentDidMount() {
    $("#services").hover(function () {
      $(".drop-down").toggleClass("active");
    });

    $("#services-mobile").click(function () {
      $(".drop-down").toggleClass("active");
    });
    $(".drawer-icon").click(function () {
      $(".drawer").toggleClass("active");
      $(".drawer-icon").toggleClass("close");
      $(".drawer-icon-close").toggleClass("close");
    });

    //dico-sec1-video
    $("#dico-sec1-video-mute").click(function () {
      // $(".drawer").toggleClass("active");
      $("#dico-sec1-video-mute").toggleClass("unmuted");
      $("#dico-sec1-video-unmute").toggleClass("muted");

      if ($("#dico-sec1-video").prop('muted')) {
        $("#dico-sec1-video").prop('muted', false);
      } else {
        $("#dico-sec1-video").prop('muted', true);
      }
    });

    $(window).bind('mousewheel', function (event) {
      $(".ekonk-sec2-bg").addClass("active");
    });

    $(document.body).on('touchmove', onScroll); // for mobile

    // callback
    function onScroll() {
      $(".ekonk-sec2-bg").addClass("active");
    }

    // $(".ekonk-sec1-bg").scroll(function(){
    //   $('html, body').animate({
    //     scrollTop: $(".ekonk-sec2").offset().top
    //   }, 1000);
    // });


    // if (window.location.pathname=='/ekonk') {
    //   var currentLocation = 'firstPage';
    //   // No need to set these inside the event listener since they are always the same.
    //   var firstHeight = $('#firstPage').offset().top,
    //       secondHeight = $('#secondPage').offset().top,
    //       thirdHeight = $('#thirdPage').offset().top;

    //   // Helper so we can check if the scroll is triggered by user or by animation.
    //   var autoScrolling = false;

    //   $(document).scroll(function(e){
    //       var scrolled = $(window).scrollTop();

    //       // Only check if the user scrolled
    //       if (!autoScrolling) {
    //         if (scrolled > 1 && currentLocation == 'firstPage') {
    //               scrollPage(secondHeight, 'secondPage');
    //           } else if (scrolled > secondHeight + 1 && currentLocation == 'secondPage') {
    //               scrollPage(thirdHeight, 'thirdPage');
    //           } else if (scrolled < thirdHeight - 1 && currentLocation == 'thirdPage') {
    //               scrollPage(secondHeight, 'secondPage');
    //           } else if (scrolled < secondHeight - 1 && currentLocation == 'secondPage') {
    //               scrollPage(firstHeight, 'firstPage');
    //           }
    //       }

    //       // Since they all have the same animation, you can avoid repetition
    //       function scrollPage(nextHeight, page) {
    //         currentLocation = page;

    //         // At this point, the page will start scrolling by the animation
    //         // So we switch this var so the listener does not trigger all the if/else
    //         autoScrolling = true;
    //         $('body,html').animate({scrollTop:nextHeight}, 1500, function () {
    //             // Once the animation is over, we can reset the helper.
    //             // Now it is back to detecting user scroll.
    //             autoScrolling = false;
    //         });
    //       }

    //     $('h1').html(scrolled);
    //     $('h1').append("/" + secondHeight);
    //     $('h1').append("/" + thirdHeight);
    //   })
    // }

  }

  state = {
    visibility: false
  }







  render() {

    return (
      <React.Fragment>
        <Helmet>
          <meta charset="utf-8" />
          <title>Vazirani Automotive | Dico</title>
          <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
          <meta name="theme-color" content="#000000" />
          <meta name="description" content="We invented a battery solution that makes electric vehicles even better than they already are." />
          <meta property="og:title" content="Ekonk | Vazirani Automotive" />
          <meta property="og:image:secure" content="https://i.ibb.co/4ZTh71j/1.jpg" />
          <meta property="og:image" content="https://i.ibb.co/4ZTh71j/1.jpg" />
          <meta property="og:image:secure_url" content="https://i.ibb.co/4ZTh71j/1.jpg" />
          <meta property="og:image:type" content="image/jpeg" />
          <meta property="og:image:width" content="400" />
          <meta property="og:image:height" content="300" />
        </Helmet>
        <div>
          <img class="drawer-icon" height="17px" src={hamMenuIcon}></img>
          <img class="drawer-icon-close" height="17px" src={hamMenuClose}></img>

          <Drawer></Drawer>

          <div class="wrapper">

            <div class="dico-sec1-bg">
              <div class="ekonk-sec5 shul-hero-section">
                <div class="video-container">
                  <video autoPlay loop muted playsInline class="full-screen-video" id="dico-sec1-video">
                    <source src={dicoTopVideo} type="video/mp4"></source>
                  </video>
                </div>
              </div>
            </div>

            <Layout>
              <div id="fullpage" class="ekonk-container">
                <ReactFullpage
                  debug
                  pluginWrapper={pluginWrapper}
                  scrollingSpeed={1500}
                  easing={"easeInOutExpo"}


                  render={comp => (
                    <ReactFullpage.Wrapper>
                      <div class="section dico-sec1">

                        <div class="mute-icon" id="dico-sec1-video-mute">
                          <img src={muteIcon} />
                        </div>
                        <div class="unmute-icon" id="dico-sec1-video-unmute">
                          <img src={unmuteIcon} />
                        </div>

                        <div class="header">
                          <div class="shul-v-logo">
                            <Link to="/">
                              <img src={vaziraniV}></img>
                            </Link>
                          </div>
                          <div class="nav" style={{ justifySelf: 'right' }}>
                            <Link fade to="/">Shul</Link>
                            <span class="shul-u">_</span>
                          </div>
                          {/* <div class="divider"></div> */}
                          <div class="nav">
                            <Link fade to="/ekonk">Ekonk</Link>
                            {/* <a id="services">Ekonk
                            <img class="drop-down-arrow" src={dropDownArrow}></img>
                        </a> */}
                            {/* <div class="drop-down">
                            <Link to="/design">Design</Link>
                            <div class="divider"></div>
                            <Link to="/engineering">Engineering</Link>
                        </div> */}
                          </div>
                          {/* <div class="divider"></div> */}
                          <div class="nav">
                            <Link fade to="/dico">Dico</Link>
                          </div>

                          <div class="shul-vazirani-logo">
                            <Link to="/"><img src={vaziraniFullLogo}></img></Link>
                          </div>
                        </div>



                        <VisibilitySensor
                          onChange={(isVisible) => {
                            this.setState({ visibility: isVisible })
                          }}
                        >
                          <div class="dico-sec1-container" style={{
                            opacity: this.state.visibility ? 0 : 1,
                            transition: 'all 3s cubic-bezier(0.16, 1, 0.3, 1) 7s'
                          }}>
                            <p >
                              We invented a battery solution that makes electric vehicles even better than they already are.
                              {/* <br /><br /> */}
                              {/* Our battery construction and material innovation enables batteries to breathe directly through the air seamlessly. Ekonk is the first car that breathes. */}
                            </p>
                            {/* <LazyLoadImage class="dico-sec1-logo-full" effect="blur" src={dicologofull}></LazyLoadImage> */}
                          </div>


                        </VisibilitySensor>









                      </div>

                      <div class="section dico-sec2">

                        <div class="gradient-dark-overlay"></div>


                        <LazyLoadImage class="dico-sec2-bg" effect="blur" src={dicopic1}></LazyLoadImage>
                        <div class="dico-sec2-para">
                          <p style={{
                            opacity: this.state.visibility ? 0 : 1,
                            transition: 'all 5s cubic-bezier(0.16, 1, 0.3, 1)'
                          }}>

                            The first technology of its kind. A solid state <b>Di</b>rect <b>Co</b>oling solution developed using nano-particle technology.
                            {/* <br/><br/>
​

Biomimicry (studying how animals and humans use breathing to regulate their body
temperatures) combined with ancient Indian manufacturing techniques resulted in the
invention of the DiCo technology.

A combination of our proprietary materials innovation and unique construction, batteries can cool directly with air.                           */}
                          </p>

                        </div>

                        <div class="dico-sec2-features">
                          <div>
                            <CountUp end={70} duration={2} useEasing={true} redraw={true}>

                              {({ countUpRef, start }) => (
                                <VisibilitySensor onChange={start} delayedCall>
                                  <h3 ref={countUpRef}>kg</h3>
                                </VisibilitySensor>
                              )}

                            </CountUp><h5>%</h5>
                            <h4>Battery cooling components eliminated</h4>
                          </div>
                          <div>
                            <LazyLoadImage class="dico-features-pic" effect="blur" src={weightreduction}></LazyLoadImage>
                            <h4>Reduces vehicle weight significantly</h4>
                          </div>
                          <div>
                            <LazyLoadImage class="dico-features-pic" effect="blur" src={range}></LazyLoadImage>
                            <h4>Enhances the performance and range of an EV</h4>
                          </div>
                        </div>

                      </div>
                      <div class="section dico-sec3">
                        <div style={{
                          opacity: this.state.visibility ? 0 : 1,
                          transition: 'all 3s cubic-bezier(0.16, 1, 0.3, 1)'
                        }} class="dico-sec3-container">
                          <LazyLoadImage class="dico-sec3-bg" effect="blur" src={dicopic2}></LazyLoadImage>

                          <div class="dico-sec3-para">
                            <Link to="/">
                              <LazyLoadImage class="dico-sec3-ekonk-logo-full" effect="blur" src={ekonklogofull}></LazyLoadImage>
                            </Link>
                            <p>
                              is our first vehicle that has DICO technology.
                            </p>
                          </div>

                        </div>

                      </div>
                    </ReactFullpage.Wrapper>
                  )}
                />





              </div>


              <Footer></Footer>
            </Layout>

          </div>

        </div>

      </React.Fragment>
    )
  }
}

export default EkonkPage
